// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
   // export const apiHost = 'http://164.52.202.90:8080/';
    // export const apiHost = 'http://localhost:9090/';
// export const apiHost = 'http://216.48.176.110:9090/';
   export const apiHost = 'https://cea.axalyn.com/';


export const environment = {
  production: false,
  apiUrl: `${apiHost}api`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * 
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
